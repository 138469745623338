<template>
  <div class="news-page">
    <div class="block-1">
      <div class="title">{{ this.content.articleTitle }}</div>
    </div>

    <div class="block-2">
      <div class="left flex">
        <div class="avatar">
          <img
            :src="`${this.content.issuerStaffPhoto}`"
            alt=""
            :onerror="defaultImg"
          />
        </div>
        <div class="author" v-if="this.content.author === undefined">
          <p>{{ this.content.issuerStaffName }}</p>
        </div>
        <div class="author" v-else>
          <p>{{ this.content.author }}</p>
        </div>
        <div class="icon">
          <div class="item">
            <i
              style="font-size: 1.5rem; margin-right: 1rem"
              class="iconfont icon-riqi"
            ></i>
            {{ this.content.createTime | formatDate }}
          </div>
          <div class="item">
            <i
              style="font-size: 1.5rem; margin-right: 1rem"
              class="iconfont icon-aixin"
            ></i>
            {{ this.content.likeNumber }}
          </div>
          <div class="item">
            <i
              style="font-size: 1.5rem; margin-right: 1rem"
              class="iconfont icon-eye"
            ></i>
            {{ this.content.browseNumber }}
          </div>
        </div>
      </div>
      <div class="mid">
        <div class="author">
          <p>{{ this.content.issuerStaffDepartment }}</p>
        </div>
        <div class="content">
          <div
            class="textarea ql-container ql-snow ql-editor ql-blank"
            v-html="content.articleContent"
          ></div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <p>{{ $t('message.news.share') }}</p>
        </div>
        <div class="functions">
          <ShareNetwork
            class="facebook"
            network="facebook"
            title="I'd like to share this news with you."
            :url="currentUrl"
          >
            <img src="../../assets/imgs/news/facebook.png" />
          </ShareNetwork>
          <ShareNetwork
            network="weibo"
            class="wechat"
            title="I'd like to share this news with you."
            :url="currentUrl"
          >
            <img src="../../assets/imgs/news/weibo.png" />
          </ShareNetwork>
          <ShareNetwork
            class="twitter"
            network="twitter"
            title="I'd like to share this news with you."
            :url="currentUrl"
          >
            <img src="../../assets/imgs/news/twitter.png" />
          </ShareNetwork>
        </div>
        <div class="title">
          <p>{{ $t('message.news.hot-views') }}</p>
        </div>
        <div class="hotnews">
          <div class="body" @click="goNews(recommendMemo[0].id)">
            <img
              loading="lazy"
              :src="`${this.recommendMemo[0].articleCoverUrl}`"
              alt=""
            />
          </div>
          <div class="body" @click="goNews(recommendMemo[1].id)">
            <img
              loading="lazy"
              :src="`${this.recommendMemo[1].articleCoverUrl}`"
              alt=""
            />
          </div>
          <div class="body" @click="goNews(recommendMemo[2].id)">
            <img
              loading="lazy"
              :src="`${this.recommendMemo[2].articleCoverUrl}`"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Editor from '@tinymce/tinymce-vue'
import { formatDate } from '@/common/date.js' // 在组件中引用date.js

export default {
  components: {
    // 'tinymce-editor': Editor,
  },

  data: function() {
    return {
      hasPhoto: true,
      allNews: [],
      popularNews: [],
      currentUrl: '',
      content: { gmt_create: '' },
      liked: false,
      imageErrors: [false, false, false],
      recommendMemo: [],
      currentLang: null,
      defaultImg:
        'this.src="' +
        require('../../assets/imgs/news/avatar_default.png') +
        '"',
      init: {
        language_url:
          'https://cdn.jsdelivr.net/gh/wt-sml/wutong_cdn/js/tinymce-lang-zh@5.2.2.js',
        language: 'zh_CN',
        height: 600,
        plugins: 'autoresize',
        toolbar: '',
        branding: false,
        menubar: false,
        statusbar: false,
        resize: false,
      },
    }
  },

  filters: {
    formatDate(time) {
      var date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd')
    },
  },
  methods: {
    useDefault(index) {
      if (this.allPhotos[index] === undefined) {
        this.imageErrors[index] = true
      } else {
        this.imageErrors[index] = false
      }
    },

    goNews: function(id) {
      window.location.href = '/news/?id=' + id
      window.scrollTo(0, 0)
    },

    like: function() {
      if (this.liked === false) {
        this.likeNews(this.content.id)
      }

      this.liked = true
    },

    goto(where) {
      this.$router.push('/news/?id=' + where)
      const urlParams = new URLSearchParams(window.location.search)
      this.newsID = urlParams.get('id')
      this.getNews(this.newsID, 0, 1)
      this.liked = false
      this.imageErrors = [false, false, false]
    },

    goAllPhotos: function() {
      this.$router.push('/all-photos/?id=' + this.content.id)
    },

    async getNews(id, sortType, languageType) {
      let response = await this.$http.get(
        `${this.$url.memo.url_memo_detail}?id=${id}&sortType=${sortType}`
      )
      this.content = response.data
      if (languageType === 1) {
        this.content.articleTitle = this.content.articleTitleEn
        this.content.articleContent = this.content.articleContentEn
      } else {
        this.content.articleTitle = this.content.articleTitleCn
        this.content.articleContent = this.content.articleContentCn
      }
    },

    async getRecommendMemo(pageNum, pageSize, languageType) {
      let response = await this.$http.get(
        `${this.$url.memo.url_memo_public_info}?pageNum=${pageNum}&pageSize=${pageSize}&types=2`
      )
      this.recommendMemo = response.data['recommendList']

      if (this.recommendMemo.length !== 0) {
        for (
          let index = 0;
          index < this.recommendMemo.length && index < 10;
          index++
        ) {
          if (languageType === 1) {
            this.recommendMemo[index].articleTitle = this.recommendMemo[
              index
            ].articleTitleEn
            this.recommendMemo[index].articleContent = this.recommendMemo[
              index
            ].articleContentEn
          } else {
            this.recommendMemo[index].articleTitle = this.recommendMemo[
              index
            ].articleTitleCn
            this.recommendMemo[index].articleContent = this.recommendMemo[
              index
            ].articleContentCn
          }
          if (this.recommendMemo[index].articleContent !== null) {
            this.recommendMemo[index].articleContent = this.recommendMemo[
              index
            ].articleContent.replace(/<[^>]+>/g, '')
          }
          this.recommendMemo[index].currentUrl =
            'http://www.mcc.sg/news/?id=' + this.recommendMemo[index].id
        }
      }
      console.log(this.recommendMemo)
    },

    async likeNews(id) {
      let formData = new FormData()
      formData.append('id', id)
      let response = await this.$http.put(
        this.$url.news.url_news_like,
        formData
      )

      if (response.msg === 'success') {
        this.content.like_number++
      }
    },

    // async browseReord() {
    //     if (sessionStorage.getItem('token')) {
    //         const {data} = await this.$http.post(`${this.$url.browse.url_memo_browseSave}`, `{'"'memoId'"': ${this.$route.query.id}}`)
    //         console.log(data);
    //     }
    //     const {data} = await this.$http.post(`${this.$url.browse.url_memo_browseSave}`, `{'"'memoId'"': ${this.$route.query.id}}`)
    //     console.log(data);
    //     console.log("browseRecord")
    // }
  },

  created() {},

  mounted() {
    // this.browseReord();
    const urlParams = new URLSearchParams(window.location.search)
    if (this.$store.state.lang === 'zh') {
      this.currentLang = 0
    } else {
      this.currentLang = 1
    }
    this.newsID = urlParams.get('id')
    this.getNews(this.newsID, 0, this.currentLang)
    this.getRecommendMemo(1, 3, this.currentLang)
  },

  computed: {
    ...mapState(['lang']),
  },

  watch: {
    lang(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === 'zh') {
          this.currLang = 0
          this.getNews(this.newsID, 0, this.currLang)
          this.getRecommendMemo(1, 3, this.currLang)
        } else {
          this.currLang = 1
          this.getNews(this.newsID, 0, this.currLang)
          this.getRecommendMemo(1, 3, this.currLang)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.tox.tox-tinymce.tox-tinymce--disabled {
  border: none;
}
.tox-notification.tox-notification--warning {
  display: none !important;
}
</style>

<style lang="scss">
.ql-snow {
  border: none !important;
}
@mixin styling($unit-size) {
  .news-page {
    padding: ($unit-size * 30) ($unit-size * 60) ($unit-size * 120)
      ($unit-size * 60);
    width: $unit-size * 1200;
    .block-1 {
      min-height: $unit-size * 100;
      width: 100%;
      border-radius: 10px 10px 10px 10px;

      background-color: #dbecff;

      .title {
        padding: ($unit-size * 20) ($unit-size * 33) ($unit-size * 20)
          ($unit-size * 33);
        font-family: Noto Sans;
        font-weight: 600;
        font-size: 36 * $unit-size;
        line-height: 60 * $unit-size;
        text-align: left;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .block-2 {
      min-height: $unit-size * 800;
      width: 100%;
      display: grid;
      .left {
        padding: ($unit-size * 32) 0 ($unit-size * 33) 0;
        width: $unit-size * 140;
        .avatar {
          img {
            width: $unit-size * 90;
            height: $unit-size * 90;
            border-radius: 54%;
          }
          margin-left: $unit-size * 25;
        }
        .author {
          margin-top: $unit-size * 14;
          height: $unit-size * 40;
          font-family: Noto Sans;
          font-size: 16 * $unit-size;
          line-height: 22 * $unit-size;
          font-weight: 500;
          text-align: center;
          border-bottom: 1px solid;
          border-color: rgba(128, 128, 128, 0.48);
        }
        .icon {
          margin-top: $unit-size * 20;
          width: $unit-size * 108;
          height: $unit-size * 52;
          font-size: 12 * $unit-size;
          line-height: 12 * $unit-size;
          text-align: left;
          color: rgba(0, 0, 0, 0.65);
          .item {
            display: flex;
            margin-bottom: 1rem;
          }
        }
        grid-column: 1;
      }

      .mid {
        width: $unit-size * 708;
        padding: ($unit-size * 32) ($unit-size * 16) 0 ($unit-size * 16);
        overflow: hidden;
        // background-color: red;
        grid-column: 2;
        .author {
          // width: $unit-size * 248;
          height: $unit-size * 45;
          font-family: Noto Sans;
          font-size: 15 * $unit-size;
          line-height: 45 * $unit-size;
          white-space: nowrap;
          &:first-letter {
            font-size: 300%;
          }
        }

        .content {
          width: 100%;
        }
        .img {
          vertical-align: middle;
          width: 100%;
          height: $unit-size * 560;
          img {
            width: 100%;
            max-height: 100%;
            object-fit: fill;
          }
        }
      }
      .right {
        width: $unit-size * 320;
        grid-column: 3;
        padding: ($unit-size * 32) 0 0 0;
        // background-color: blueviolet;
        .title {
          font-family: Noto Sans;
          font-size: 25 * $unit-size;
          font-weight: 800;
          line-height: 30 * $unit-size;
        }
        .functions {
          width: $unit-size * 312;
          height: $unit-size * 48;
          margin-top: $unit-size * 20;
          padding-bottom: $unit-size * 30;
          display: flex;
          div {
            cursor: pointer;
          }

          img {
            height: 4.8rem;
            width: 4.8rem;
            margin-right: 2rem;
          }
        }
        .hotnews {
          cursor: pointer;
          width: 100%;
          height: 180 * $unit-size;
          margin-top: 24 * $unit-size;
          display: inline-block;
          .body {
            margin-bottom: 20 * $unit-size;
            height: 100%;
            .info {
              width: 320 * $unit-size;
              height: 45 * $unit-size;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.5) 0%,
                rgba(0, 0, 0, 0) 15%,
                rgba(0, 0, 0, 0) 70%,
                rgba(0, 0, 0, 0.05) 75%,
                rgba(0, 0, 0, 0.7) 100%
              );
              text-align: center;
              position: relative;
              border-radius: 0 0 10px 10px;
              margin-top: -45 * $unit-size;
              .title1 {
                width: 280 * $unit-size;
                padding: ($unit-size * 7) ($unit-size * 20) ($unit-size * 7)
                  ($unit-size * 20);
                font-size: 16 * $unit-size;
                line-height: 18 * $unit-size;
                height: 34 * $unit-size;
                font-weight: 500;
                overflow: hidden;
                display: flex;
                vertical-align: middle;
                color: rgb(255, 255, 255);
              }
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px 10px 10px 10px;
              object-fit: cover;
              position: relative;
              border-color: rgba(0, 0, 0, 0.05);
              border-width: 1px;
              border-style: solid;
            }
          }
          .body:hover {
            .info {
              width: 320 * $unit-size;
              height: 180 * $unit-size;
              background-color: rgba(84, 151, 242, 0.8);
              margin-top: -180 * $unit-size;
              border-radius: 10px;
              .title1 {
                width: 280 * $unit-size;
                padding: ($unit-size * 6) ($unit-size * 20) ($unit-size * 6)
                  ($unit-size * 20);
                font-size: 16 * $unit-size;
                line-height: 17 * $unit-size;
                height: 168 * $unit-size;
                font-weight: 500;

                color: rgb(255, 255, 255);
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 100vw) {
  @include styling($unit-size: 100vw/1366);
}
.textarea {
  // overflow: hidden;

  //   max-height: 800px;
  border-radius: 2px;
  ::v-deep {
    img,
    p,
    table,
    span {
      overflow: hidden; /* 隐藏超出容器的内容 */
      // white-space: normal; /* 允许正常换行 */
      // word-wrap: break-word; /* 允许在单词内部换行 */
      // max-width: 100%;
    }
    blockquote {
      border-left: 8px solid #d0e5f2;
      padding: 10px 10px;
      margin: 10px 0;
      background-color: #f1f1f1;
    }

    code {
      font-family: monospace;
      background-color: #eee;
      padding: 3px;
      border-radius: 3px;
    }
    pre > code {
      display: block;
      padding: 10px;
    }

    table {
      border-collapse: collapse;
      overflow-x: auto;
      display: -webkit-box;
      -webkit-overflow-scrolling: touch;
    }
    td,
    th {
      border: 1px solid #ccc;
      min-width: 50px;
      height: 20px;
    }
    th {
      background-color: #f1f1f1;
    }

    ul,
    ol {
      padding-left: 20px;
    }

    input[type='checkbox'] {
      margin-right: 5px;
    }
    ul,
    ol {
      list-style: revert;
    }
  }
}
</style>
